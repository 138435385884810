.jlt-product-desc {
    margin-top: 0;
    > span {
        margin: 32px 0;
        color: #475467;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
    }
}
