.jlt-wstandard {
    h1 {
        margin: 36px 0;
    }
    h2 {
        margin: 32px 0;
    }
    h3 {
        margin: 28px 0;
    }
    h4 {
        margin: 24px 0;
    }
    h5 {
        margin: 20px 0;
    }
    h6 {
        margin: 18px 0;
    }
    p {
        margin-bottom: 18px;
    }
    ol,
    ul,
    menu {
        list-style: initial;
        margin-left: 1rem;
        // margin: initial;
        // padding: inherit;
    }
    ul {
        li {
            margin: 12px 0;
            a {
                color: var(--primary);
            }
        }
    }
}
