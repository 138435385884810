//mega menu
.jlt_mega_menu {
    margin-top: -16px;
}

.jlt_grid_container {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    // grid-auto-rows: 48px;
    grid-auto-flow: dense;
    > .jlt_big {
        grid-row: span 3;
    }
    > .jlt_medium {
        grid-row: span 2;
    }
}
