$phone: 640px;
$tablet: 768px;
$laptop: 1024px;
$desktop: 1280px;

@mixin containerSize($size) {
    $result: $size;
    @media only screen and (min-width: $desktop) {
        $result: $desktop;
        width: $result;
    }
    @media only screen and (max-width: $desktop) {
        $result: 100%;
        width: $result;
    }
    @media only screen and (max-width: $laptop) {
        $result: 100%;
        width: $result;
    }
    @media only screen and (max-width: $tablet) {
        $result: 100%;
        width: $result;
    }
    @media only screen and (max-width: $phone) {
        $result: 100%;
        width: $result;
    }
}

@mixin fontSize($size) {
    $result: $size;
    @media only screen and (min-width: $desktop) {
        $result: $size;
        font-size: $result;
    }
    @media only screen and (max-width: $desktop) {
        $result: $size * 1;
        font-size: $result;
    }
    @media only screen and (max-width: $laptop) {
        $result: $size * 0.75;
        font-size: $result;
    }
    @media only screen and (max-width: $tablet) {
        $result: $size * 0.5;
        font-size: $result;
    }
    @media only screen and (max-width: $phone) {
        $result: $size * 0.5;
        font-size: $result;
    }
}
