@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";
@import "./variables";
@import "./normalize";
// @import "./libraries/_nprogress";
@import "./megamenu";
@import "./mixin";
@import "./single-docs";
@import "./single-blog";
@import "./wp-standard";
@import "./product-details";
@import "./fullScreenLoader";

.jlt-wpadminify {
  --primary: #3a3ae9;
  background-color: #fff;
}
.jlt-jeweltheme {
  --primary: #256eff;
}
.jlt-reactadmin {
  --primary: #fb346d;
}
.jlt-wpspotlight {
  --primary: #256eff;
}
.jlt-pixarlabs {
  --primary: #551a8b;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #fafcff;
}

img {
  height: unset;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: #101828;
}

h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2.5rem;
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1rem;
}
h5 {
  font-size: 1rem;
}
h6 {
  font-size: 0.8rem;
}

p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: #475467;
}

.jt-container {
  @include containerSize(1280px);
  padding: 0 32px;
  .row {
    margin-top: 32px;
    > .column {
      margin: 0 16px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.jlt-btn {
  color: #ffffff;
  background: #0d49ce;
}

// box-shadow
.jlt-bx-shadow {
  box-shadow: 0px 0px 36px 0px #00000061;
}

.jlt-unorder-list {
  list-style: inside;
  list-style-type: disc;
  li {
    a {
      color: var(--primary);
    }
  }
}
.jlt-order-list {
  list-style: inside;
  list-style-type: auto;
  li {
    a {
      color: var(--primary);
    }
  }
}

//tailwind classes
@layer utilities {
  .checkout-clip {
    clip-path: polygon(100% 0%, 0 100%, 100% 100%);
  }
}
@layer components {
  .trangle-arrow::before {
    .mega-menu,
    .normal-sub-menu {
      content: "";
      position: absolute;
      top: 12px;
      // left: 34.5rem;
      left: 52px;
      height: 1.5rem;
      width: 1.5rem;
      border-top-left-radius: 0.25rem;
      border-width: 1px 0 0 1px;
      border-style: solid;
      border-color: rgba(173, 173, 188, 0.4);
      z-index: 6;
      transform: rotate(45deg);
      background-color: white;
    }
    .mega_menu_grid {
      left: 180px;
    }
    // .normal-sub-menu {
    //     left: 52px;
    // }
  }
  .shadow-custom {
    @apply jlt-shadow-[0px_0px_20px_5px_rgba(173,173,188,0.1)];
  }
}

// mega menu
.jlt__mega__menu {
  &::before {
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    margin: auto;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid #fff;

    // width: 20px;
    // height: 15px;
    // background: white;
    // clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
    // border: 1px solid #ddd;

    transition: all 0.5s;
    opacity: 0;
    // transform: translateY(0);
    z-index: 99999;
  }
}

// p {
//     color: $paragraph_font_color;
//     font-family: $paragraph_font_family;
//     font-size: $paragraph_font_size;
//     line-height: $paragraph_line_height;
//     font-weight: $paragraph_font_weight;
// }

.active-dash-menu {
  box-shadow: 0px 0px 20px rgb(173 173 188 / 15%);
}

//header styles on scroll
#header {
  // margin-top: 32px;
  transition: all 0.2s ease-in-out;
  // position: fixed;
  position: absolute;
  width: 100%;
  top: 0px;
  transform: translateY(0);
  z-index: 9999;
  > #container {
    // background: #fff;
    padding: 10px 32px;
    border-radius: 8px;
    // box-shadow: 0px 10px 25px 0px rgba(173, 173, 188, 0.4);
  }
}

.jlt-header-tf {
  transform: translateY(-100px) !important;
}

.jlt-is-sticky {
  position: fixed !important;
  margin-top: 0 !important;
  background: #fff;
  box-shadow: 0px 10px 25px 0px rgba(173, 173, 188, 0.4);
  transform: translateY(0) !important;
  z-index: 9999;
  > #container {
    box-shadow: none !important;
  }
}

.jlt-submenu {
  &::before {
    bottom: -22px !important;
  }
}

.jlt-header-hide {
  margin-top: 12px !important;
  position: static !important;
  #container {
    background: none !important;
    box-shadow: none !important;
    justify-content: flex-start !important;
    column-gap: 90px !important;
    .jlt-hide-menu-text {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #344054;
      margin-top: 8px;
    }
  }
}

//reading progress
.jlt_progress_container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 4px;
  .jlt_progress_line {
    background: var(--primary);
    height: 100%;
  }
}

//modal animation
@keyframes anim {
  0% {
    opacity: 0;
    transform: scale3d(1.1, 1.1, 1);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

// Preloader
main.preloader {
  --hue: 223;
  --bg: hsl(var(--hue), 90%, 95%);
  --fg: hsl(var(--hue), 90%, 5%);
  --primary: #256eff;
  --trans-dur: 0.3s;
  position: relative;
  width: 100%;
  height: 100%;
  .pl {
    display: block;
    overflow: visible;
    width: 8em;
    height: 8em;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-68%, -50%);
  }
  .pl__ring {
    stroke: hsla(var(--hue), 90%, 5%, 0.1);
    transition: stroke var(--trans-dur);
  }
  .pl__worm {
    stroke: var(--primary);
    transform-origin: 64px 64px;
    visibility: hidden;
  }
  .pl__worm--moving {
    animation: rotation 2s infinite linear;
    visibility: visible;
  }

  /* Animations */
  @keyframes rotation {
    from {
      // stroke-dasharray: 22 307.86 22;
      transform: rotate(0deg);
    }
    to {
      // stroke-dasharray: 2 347.86 2;
      transform: rotate(359deg);
    }
  }
}
.action-loader {
  main.preloader svg {
    width: 2.5em;
    height: 2.5em;
    transform: translate(30%, -18%);
  }
}

// FOMO Styles
// #fomo {
//     animation: anim;
//     animation-duration: 0.3s;
//     animation-fill-mode: forwards;
//     background-color: #fff;
//     border-radius: 50px;
//     padding: 0;
//     position: fixed;
//     bottom: 30px;
//     left: 15px;
//     height: 80px;
//     width: 350px;
//     z-index: 999;
//     -webkit-transition: all 0.4s;
//     transition: all 0.4s;
//     -webkit-box-shadow: 0 5px 30px rgba(0, 0, 0, 0.4);
//     box-shadow: 0 5px 30px rgba(0, 0, 0, 0.4);
//     .product_image {
//         border-radius: 50%;
//         width: 80px;
//         height: 80px;
//     }
//     .wrapper {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         width: 75%;
//         height: 100%;
//         padding: 10px;
//         position: absolute;
//         top: 0;
//         left: 25%;
//         .buyer {
//             color: #000;
//             font-size: 13px;
//             line-height: 1;
//             margin-bottom: 5px;
//             .buyer_name {
//                 font-weight: 600;
//             }
//         }
//         .product_name {
//             font-size: 16px;
//             font-weight: 700;
//             text-decoration: none;
//             color: #00bf42;
//             line-height: 1;
//             margin-bottom: 5px;
//         }
//         .time_diff {
//             font-size: 12px;
//             line-height: 1;
//         }
//     }
// }

// fomo style
.jlt-fomo-wrapper {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.4);
  transform: translateY(300px);
  transition: all 0.4s;
}

// pricing tooltip
.jlt-pricing-tooltip-wrapper {
  .jlt-pricing-tooltip-content {
    display: none;
    box-shadow: 0 2px 18px 5px rgba(73, 104, 126, 0.2);
    &::after {
      position: absolute;
      content: "";
      right: 10px;
      bottom: -7px;
      height: 0;
      width: 0;
      border-width: 8px 5px 0;
      border-style: solid;
      z-index: 1;
      pointer-events: none;
      right: 12px;
      border-color: #fff transparent transparent;
    }
  }
  &:has(span:hover) {
    .jlt-pricing-tooltip-content {
      display: block !important;
    }
  }
}

//responsive
@media only screen and (max-width: 1024px) {
  #header {
    margin-top: 0;
  }

  h1 {
    font-size: 2.75rem;
  }
  h2 {
    font-size: 2.25rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.25rem;
  }
  h5 {
    font-size: 0.9rem;
  }
  h6 {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 768px) {
  .jt-container {
    padding: 0 16px;
  }
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 0.8rem;
  }
  .jlt-fomo-wrapper {
    display: none;
  }
}

// @media only screen and (max-width: 640px) {
//     h1 {
//         font-size: 3rem;
//     }
//     h2 {
//         font-size: 2.5rem;
//     }
//     h3 {
//         font-size: 2rem;
//     }
//     h4 {
//         font-size: 1.5rem;
//     }
//     h5 {
//         font-size: 1rem;
//     }
//     h5 {
//         font-size: 1rem;
//     }
//     h6 {
//         font-size: 0.8rem;
//     }
// }

.mb-btns-full a {
  flex-basis: 100%;
}

.jlt-webkit-wrapper {
  a.mb-btn {
    display: -webkit-box;
  }
}

.jlt-max-content {
  width: max-content;
}

.jlt-table-of-content {
  .jlt-coupons {
    top: calc(50% + 30px);
  }
}
