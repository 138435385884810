.jlt_pricing_full_overlay_loader {
    width: 100vw;
    height: 100vh;
    background: #000000a3;
    z-index: 999999;
    position: fixed;
    top: 0;
    left: 0;
    &::after {
        position: absolute;
        content: "";
        top: calc(50% - 32px);
        left: calc(50% - 32px);
        pointer-events: none;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        border: 3px solid #ffffff7a;
        border-top-color: #fff;
        animation: ani1 1s ease infinite;
    }
}

@keyframes ani1 {
    0% {
        transform: rotate(0turn);
    }
    100% {
        transform: rotate(1turn);
    }
}
